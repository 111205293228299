.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.titulotermos {
  font-size: 40px;
  font-weight: bolder;
  font-style: normal;
}
.titulotermos2 {
  font-size: 10px;
  font-weight: bolder;
  font-style: normal;
}
.inline {
  display: inline-block;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  border: 1px solid black;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #000;
  text-decoration: none;
}
li {
  list-style:circle;
  font-size: 12px;
}
p {
  color: #000;
  font-size: 14px;
}
.logo {
  width: 150px;
  height: 150px;
  background-image: url("./images/logo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.ios {
  width: 180px;
  height: 60px;
  background-image: url("./images/ios.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.android {
  width: 168px;
  height: 60px;
  background-image: url("./images/android.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcvia7 {
  width: 150px;
  height: 150px;
  background-image: url("./images/appcvia7.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appc1taxi {
  width: 150px;
  height: 150px;
  background-image: url("./images/appc1taxi.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcdriver45 {
  width: 150px;
  height: 150px;
  background-image: url("./images/appcdriver45.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcdrive45 {
  width: 150px;
  height: 150px;
  background-image: url("./images/appcdrive45.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcexpress75{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcexpress75.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcgogogo{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcgogogo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcmobi{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcmobi.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcshopper{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcshopper.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcrocha{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcrocha.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmrocha{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmrocha.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.appmspeedgreen{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmspeedgrenn.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcspeedgrenn{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcspeedgrenn.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcspeedgreen{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcspeedgrenn.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmspeedgrenn{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmspeedgrenn.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.appcurbano{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcurbano.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcvaide10{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcvaide10.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appm1taxi{
  width: 150px;
  height: 150px;
  background-image: url("./images/appm1taxi.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmdriver45{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmdriver45.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmdrive45{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmdrive45.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmexpress75{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmexpress75.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmgogogo{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmgogogo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmmobi{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmmobi.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmshopper{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmshopper.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmurbano{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmurbano.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmvaide10{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmvaide10.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmvia7{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmvia7.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmvoudeale{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmvoudeale.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcvoudeale{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcvoudeale.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmrota92{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmrota92.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcrota92{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcrota92.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.appmgoiasdel{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmgoiasdel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcgoiasdel{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcgoiasdel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmgoiaisdel{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmgoiaisdel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appcgoiaisdel{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcgoiaisdel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}


.appcprime{
  width: 150px;
  height: 150px;
  background-image: url("./images/appcprime.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}
.appmprime{
  width: 150px;
  height: 150px;
  background-image: url("./images/appmprime.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}